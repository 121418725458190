<template>
    <div class="cookie-dlg"
        :class="{show:showing}">

        <div class="box">
            <div class="body">
                <div class="text-center">
                    <img class="logo"
                        src="https://r.v-office.com/v333/1612415125000/img/logo-walter.png"
                        alt="">
                    <div class="section-title">
                        Cookies
                    </div>

                </div>
            </div>

            <div class="body"
                v-show="setup">

                <div class="checkbox checkbox-success">
                    <input type="checkbox"
                        id="cookie_own"
                        onclick="return false;"
                        checked>
                    <label for="cookie_own">{{voMsg('cookies.own')}}</label>
                </div>
                <div class="checkbox checkbox-success">
                    <input type="checkbox"
                        id="cookie_others"
                        v-model="cookiesOk">
                    <label for="cookie_others">{{voMsg('cookies.others')}}</label>
                </div>
                <div class="checkbox checkbox-success">
                    <input type="checkbox"
                        id="googleMaps"
                        @click="setGoogleMaps()">
                    <label for="googleMaps">GoogleMaps zur Standortanzeige der Objekte verwenden.</label>
                </div>


            </div>
            <div class="body"
                v-show="!setup">
                <div id="cookie-img"
                    class="cookie-img">
                    <img class="img-responsive"
                        src="https://r.v-office.com/v333/1612415125000/img/cookies.jpg"
                        alt="">
                </div>
                <div id="cookie-hint"
                    class="pt-30">
                    Diese Website verwendet Cookies. Nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer <a href="./agb">Datenschutzerklärung.</a> Klicken Sie auf <span class="text-bold">Ich stimme zu</span>, um Cookies zu akzeptieren und direkt unsere Website besuchen zu können.
                </div>
            </div>


            <div class="footer"
                v-show="setup">

                <button type="button"
                    @click="onSaveBtnClick"
                    class="btn btn-primary">{{voMsg('cookies.saveselection')}}</button>

            </div>
            <div class="footer"
                v-show="!setup">

                <button type="button"
                    @click="onSetupBtnClick"
                    class="btn btn-default">{{voMsg('cookies.setup')}}</button>
                <button type="button"
                    @click="onOkBtnClick"
                    class="btn btn-primary">Ich stimme zu</button>
            </div>
        </div>
    </div>


</template>

<script>
    import V from 'voUtils/V.js'
    import {
    	saveSelection, hasCookieChoice, isCookiesOk
    }
    from 'voUtils/TagEx.js'

    export default {
    	voVueComponent: 'voffice-cookie-box',
    	props: {},
    	data: function() {
    		return {
    			showing: !hasCookieChoice(),
    			setup: false,
    			cookiesOk: isCookiesOk(),
    			useGoogleMaps: true
    		}
    	},
    	mounted: function() {
    		var GoogleMaps = localStorage.getItem("useGoogleMaps");
    		var winHeight = $(window).height();
    		if (GoogleMaps === null) {
    			localStorage.setItem("useGoogleMaps", true);
    			$('#googleMaps').prop("checked", true);
    		} else if (GoogleMaps === 'false') {
    			$('#googleMaps').prop("checked", false);
    		} else if (GoogleMaps === 'true') {
    			$('#googleMaps').prop("checked", true);
    		}
    		if (winHeight < 760) {
    			$('#cookie-img').css({
    				'display': 'none'
    			});
    			$('#cookie-hint').removeClass('pt-30');
    		}
    	},
    	computed: {
    		introMsg: function() {
    			return this.voMsg('cookies.intro').split('\n').join('<br>')
    		}
    	},
    	methods: {
    		onSetupBtnClick: function() {
    			this.setup = true;
    		},
    		onSaveBtnClick: function() {
    			saveSelection(this.cookiesOk);
    			this.showing = false;

    		},
    		onOkBtnClick: function() {
    			saveSelection(true);
    			this.showing = false;
    			localStorage.setItem("useGoogleMaps", true);
    		},
    		setGoogleMaps: function() {
    			if ($('#googleMaps').is(':checked')) {
    				localStorage.setItem("useGoogleMaps", true);
    			} else {
    				localStorage.setItem("useGoogleMaps", false);
    			}
    		}

    	}

    };
</script>

<style scoped>
    .cookie-dlg {
    	padding: 20px;
    	display: none;
    	z-index: 990;
    	background: rgba(0, 0, 0, 0.5);

    	position: fixed !important;
    	left: 0px !important;
    	right: 0px !important;
    	bottom: 0px !important;
    	top: 0px !important;
    	margin: 0px !important;

    	.privacy-link {
    		color: inherit;
    		text-decoration: underline;
    	}

    }

    .box {
    	position: relative;
    	width: 600px;

    	background: #ffffff;
    	border: solid 1px #cccccc;
    	border-radius: 0px;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

    	left: auto;
    	margin-right: auto;
    	margin-left: auto;
    }

    .body {
    	padding: 15px 15px;
    	overflow: auto;
    	position: relative;
    	-webkit-overflow-scrolling: touch;
    	font-size: 13px;

    }


    .footer {
    	border-top: 1px solid rgb(235, 235, 235);
    	padding: 12px 15px;
    	text-align: right;
    }

    .header {
    	border-bottom: 1px solid rgb(235, 235, 235);
    	padding: 6px 15px;
    	text-align: center;
    }

    .cookie-dlg.show {
    	display: block;
    }





    @media (max-width:767px) {

    	.cookie-dlg {
    		padding: 0;
    		top: auto !important;

    	}



    	.box {
    		width: 100%;
    		height: 100%;
    		border-radius: 0px;
    		border: none;

    	}



    }
</style>