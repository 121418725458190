<template>
    <div class="dlg"
        ref="dlg"
        :class="{show:showing}">

        <div class="box layout-column">

            <div class="header">

                <h4>{{headerLabel}}</h4>

            </div>
            <div class="body flex">
                <slot></slot>
            </div>
            <div class="footer"
                v-show="applyLabel">
                <button type="button"
                    @click="onCloseBtnClick"
                    class="btn btn-primary">{{applyLabel}}</button>
            </div>

        </div>
    </div>

</template>

<script>
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'voffice-popup-dlg',
    	props: {
    		triggerId: String,
    		applyLabel: String,
    		headerLabel: String
    	},
    	data: function() {
    		return {
    			showing: false,
    			hasBeenShown: false
    		}
    	},

    	mounted: function() {
    		this.triggerEl = document.getElementById(this.triggerId);
    		if (this.triggerEl) {
    			this.triggerEl.addEventListener('click', this.toggleShow);
    		}

    		this.$refs.dlg.addEventListener('click', (e) => {
    			if (e.target.isSameNode(this.$refs.dlg)) {
    				this.hide();
    			}
    		});

    	},
    	methods: {
    		onCloseBtnClick: function() {
    			this.hide();
    		},
    		show: function() {
    			this.showing = true;
    		},
    		hide: function() {
    			this.showing = false;
    		},
    		toggleShow: function(event) {
    			this.showing = !this.showing;
    		}
    	},
    	watch: {
    		showing: function() {
    			if (this.showing) {
    				this.hasBeenShown = true;
    			} else if (this.hasBeenShown) {
    				this.$emit('closed');
    			}

    		}
    	}
    }
</script>


<style scoped>
    .dlg {
    	padding: 20px;
    	display: none;
    	z-index: 999;
    	background: rgba(0, 0, 0, 0.5);

    	position: fixed !important;
    	left: 0px !important;
    	right: 0px !important;
    	bottom: 0px !important;
    	top: 0px !important;
    	margin: 0px !important;

    }

    .box {
    	position: relative;
    	width: 800px;
    	height: 800px;
    	max-height: 100%;
    	max-width: 100%;


    	background: #ffffff;
    	border: solid 1px #cccccc;
    	border-radius: 0px;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

    	left: auto;
    	margin-right: auto;
    	margin-left: auto;
    }

    .body {
    	padding: 22px 15px;
    	overflow: auto;
    	position: relative;
    	-webkit-overflow-scrolling: touch;

    }


    .footer {
    	border-top: 1px solid rgb(235, 235, 235);
    	padding: 12px 15px;
    	text-align: right;
    }

    .header {
    	border-bottom: 1px solid rgb(235, 235, 235);
    	padding: 6px 15px;
    	text-align: center;
    }

    .dlg.show {
    	display: block;
    }





    @media (max-width:767px) {

    	.dlg {
    		padding: 0;
    	}

    	.box {
    		width: 100%;
    		height: 100%;
    		border-radius: 0px;
    		border: none;

    	}



    }
</style>