var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cookie-dlg", class: { show: _vm.showing } },
    [
      _c("div", { staticClass: "box" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.setup,
                expression: "setup"
              }
            ],
            staticClass: "body"
          },
          [
            _c("div", { staticClass: "checkbox checkbox-success" }, [
              _c("input", {
                attrs: {
                  type: "checkbox",
                  id: "cookie_own",
                  onclick: "return false;",
                  checked: ""
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "cookie_own" } }, [
                _vm._v(_vm._s(_vm.voMsg("cookies.own")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox checkbox-success" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.cookiesOk,
                    expression: "cookiesOk"
                  }
                ],
                attrs: { type: "checkbox", id: "cookie_others" },
                domProps: {
                  checked: Array.isArray(_vm.cookiesOk)
                    ? _vm._i(_vm.cookiesOk, null) > -1
                    : _vm.cookiesOk
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.cookiesOk,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.cookiesOk = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.cookiesOk = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.cookiesOk = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "cookie_others" } }, [
                _vm._v(_vm._s(_vm.voMsg("cookies.others")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox checkbox-success" }, [
              _c("input", {
                attrs: { type: "checkbox", id: "googleMaps" },
                on: {
                  click: function($event) {
                    return _vm.setGoogleMaps()
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "googleMaps" } }, [
                _vm._v("GoogleMaps zur Standortanzeige der Objekte verwenden.")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.setup,
                expression: "!setup"
              }
            ],
            staticClass: "body"
          },
          [_vm._m(1), _vm._v(" "), _vm._m(2)]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.setup,
                expression: "setup"
              }
            ],
            staticClass: "footer"
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.onSaveBtnClick }
              },
              [_vm._v(_vm._s(_vm.voMsg("cookies.saveselection")))]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.setup,
                expression: "!setup"
              }
            ],
            staticClass: "footer"
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button" },
                on: { click: _vm.onSetupBtnClick }
              },
              [_vm._v(_vm._s(_vm.voMsg("cookies.setup")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.onOkBtnClick }
              },
              [_vm._v("Ich stimme zu")]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "text-center" }, [
        _c("img", {
          staticClass: "logo",
          attrs: {
            src:
              "https://r.v-office.com/v333/1612415125000/img/logo-walter.png",
            alt: ""
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "section-title" }, [
          _vm._v("\n                    Cookies\n                ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "cookie-img", attrs: { id: "cookie-img" } },
      [
        _c("img", {
          staticClass: "img-responsive",
          attrs: {
            src: "https://r.v-office.com/v333/1612415125000/img/cookies.jpg",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-30", attrs: { id: "cookie-hint" } }, [
      _vm._v(
        "\n                Diese Website verwendet Cookies. Nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer "
      ),
      _c("a", { attrs: { href: "./agb" } }, [_vm._v("Datenschutzerklärung.")]),
      _vm._v(" Klicken Sie auf "),
      _c("span", { staticClass: "text-bold" }, [_vm._v("Ich stimme zu")]),
      _vm._v(
        ", um Cookies zu akzeptieren und direkt unsere Website besuchen zu können.\n            "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }